import React from "react";
import { graphql } from "gatsby";
import { Col, Container, Row } from "react-bootstrap";
import { PrismicRichText } from "@prismicio/react";
import { GatsbyImage } from "gatsby-plugin-image";

import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import HeaderBackground from "../components/blocks/HeaderBackground";
import Anchor from "../components/elements/Anchor";

import { constructActiveDoc, handleize } from "../utilities";

import "../styles/pages/division.scss";

const Brands = ({ data }) => {
  if (!data) return null;

  const { settings, page } = data;
  const { title, image, image_mobile: imageMobile, divisions } = page.data;

  return (
    <>
      <Seo page={page} />
      <Layout settings={settings} activeDocMeta={constructActiveDoc(page)}>
        <HeaderBackground
          title={title}
          image={image}
          imageMobile={imageMobile}
        />
        <Container className="mt-4">
          {divisions.map((item, index) => {
            const { id, url, data: division } = item.division.document;

            return (
              <section key={id} className="mb-5 text-center text-lg-start">
                <div className="display-1 mb-lg-5 text-fire border-bottom border-2 border-fire">
                  {index + 1 < 10 && "0"}
                  <strong>{index + 1}</strong>
                </div>
                <Row>
                  <Col lg="4" className="division__header mb-4">
                    <PrismicRichText field={division.header.richText} />
                  </Col>
                  <Col lg="8">
                    {division.body.map((brand) => {
                      if (brand.slice_type === "brand") {
                        return (
                          <Anchor
                            href={`${url}#${handleize(brand.primary.name)}`}
                            title={brand.primary.name}
                            key={brand.id}
                          >
                            <GatsbyImage
                              image={brand.primary.logo.gatsbyImageData}
                              alt={brand.primary.logo.alt || ""}
                              className="division__logo"
                            />
                          </Anchor>
                        );
                      }
                      return null;
                    })}
                  </Col>
                </Row>
              </section>
            );
          })}
        </Container>
      </Layout>
    </>
  );
};

export const query = graphql`
  query brandsQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicBrands(lang: { eq: $lang }) {
      ...prismicBrandsFragment
    }
  }
`;

export default Brands;
