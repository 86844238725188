import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { PrismicRichText } from "@prismicio/react";

import "../../styles/components/blocks/header-background.scss";

const HeaderBackground = ({ title, image, imageMobile }) => {
  const hmRatio =
    imageMobile.gatsbyImageData.height / imageMobile.gatsbyImageData.width;
  const hRatio = image.gatsbyImageData.height / image.gatsbyImageData.width;

  return (
    <header
      className="header-background"
      style={{ "--hm": `${hmRatio * 100}vw`, "--h": `${hRatio * 100}vw` }}
    >
      <figure className="header-background__image">
        {image.gatsbyImageData && (
          <GatsbyImage
            image={image.gatsbyImageData}
            alt={image.alt || title.text || title || ""}
            className={
              imageMobile?.gatsbyImageData ? "d-none d-md-block" : null
            }
            loading="eager"
          />
        )}
        {imageMobile?.gatsbyImageData && (
          <GatsbyImage
            image={imageMobile.gatsbyImageData}
            alt={imageMobile.alt || title.text || title || ""}
            className="d-md-none"
            loading="eager"
          />
        )}
      </figure>
      <div className="header-background__container">
        <div className="header-background__content p-3">
          {title && <PrismicRichText field={title.richText} />}
        </div>
      </div>
    </header>
  );
};

export default HeaderBackground;
