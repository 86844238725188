/**
 * The Link Resolver used for the Prismic repository. This function converts a
 * Prismic document to a URL within your app. It is used throughout your app to
 * resolve document links and support editor previews.
 *
 * {@link https://prismic.io/docs/technologies/link-resolver-gatsby}
 *
 * @param doc Prismic document to resolve to a URL within your app.
 * @returns URL for the provided Prismic document.
 */

const defaultLanguage = require("../../config/site-config").default?.siteLang;

exports.linkResolver = (doc) => {
  switch (doc.type) {
    case "home": {
      return doc.lang === defaultLanguage ? "/" : `/${doc.lang.split("-")[0]}/`;
    }
    case "about": {
      return doc.lang === defaultLanguage
        ? "/about/"
        : `/${doc.lang.split("-")[0]}/about/`;
    }
    case "news": {
      return doc.lang === defaultLanguage
        ? "/news/"
        : `/${doc.lang.split("-")[0]}/news/`;
    }
    case "post": {
      return doc.lang === defaultLanguage
        ? `/${doc.uid}/`
        : `/${doc.lang.split("-")[0]}/${doc.uid}/`;
    }
    case "contact": {
      return doc.lang === defaultLanguage
        ? "/contact/"
        : `/${doc.lang.split("-")[0]}/contact/`;
    }
    case "b2b": {
      return doc.lang === defaultLanguage
        ? "/b2b/"
        : `/${doc.lang.split("-")[0]}/b2b/`;
    }
    case "simple_page": {
      return doc.lang === defaultLanguage
        ? `/${doc.uid}/`
        : `/${doc.lang.split("-")[0]}/${doc.uid}/`;
    }
    case "division": {
      return doc.lang === defaultLanguage
        ? `/division/${doc.uid}/`
        : `/${doc.lang.split("-")[0]}/division/${doc.uid}/`;
    }
    case "brands": {
      return doc.lang === defaultLanguage
        ? `/brands/`
        : `/${doc.lang.split("-")[0]}/brands/`;
    }
    case "careers": {
      return doc.lang === defaultLanguage
        ? `/careers/`
        : `/${doc.lang.split("-")[0]}/careers/`;
    }
    case "job_listing": {
      return doc.lang === defaultLanguage
        ? `/careers/${doc.uid}/`
        : `/careers/${doc.lang.split("-")[0]}/${doc.uid}/`;
    }
    default: {
      return "/";
    }
  }
};
